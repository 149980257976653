// bounce
$('.bounce_self').on('mouseenter', (e) => {
    $(e.target).addClass('bounce');
    $(e.target).on('animationend', () => {
        $(e.target).removeClass('bounce');
    });
});

$(".bounce_parent").on("mouseenter", (e) => {
    $(e.target).children('.bounce_child').addClass('bounce');
    $(e.target).children('.bounce_child').on('animationend', () => {
        $(e.target).children().removeClass('bounce');
    });
});

// wiggle
$('.wiggle_parent_x').on({
    mouseenter: (e) => {
        $(e.target).children('.wiggle_child').addClass('x-wiggle');
    },
    touchstart: (e) => {
        $(e.target).children('.wiggle_child').addClass('x-wiggle');
    }
});

$('.wiggle_parent_x').on({
    mouseleave: (e) => {
        $(e.target).children('.wiggle_child').removeClass('x-wiggle');
    },
    touchend: (e) => {
        setTimeout(() => {
            $(e.target).children('.wiggle_child').removeClass('x-wiggle');
        }, 10);
    }
});

// header
$('.menu').on('click', (e) => {
    $('.header-nav').toggleClass('active');
    $(e.target).toggleClass('active');
});

$(".nav-list").on("mouseenter", (e) => {
    $('.nav-list').addClass('notActive');
    $(e.target).addClass('active');
});

$(".nav-list").on("mouseleave", (e) => {
    $('.nav-list').removeClass('notActive');
    $(e.target).removeClass('active');
});

window.addEventListener('resize', () => {
    if (window.innerWidth >= 768) {
        $('.header-nav').removeClass('active');
        $('.menu').removeClass('active');
    }
    headerTop();
});

$(`.nav-${$('body').data('page')}`).addClass('on-active');

function headerTop() {
    if ($('main').scrollTop() > 80) {
        $('header').css('top', '-80px');
    } else {
        if (1440 <= $(window).width()) {
            $('header').css('top', '40px');
        } else if (1200 <= $(window).width() && $(window).width() < 1440) {
            $('header').css('top', '30px');
        } else if (768 <= $(window).width() && $(window).width() < 1200) {
            $('header').css('top', '25px');
        } else if ($(window).width() < 768) {
            $('header').css('top', '0px');
        }
    };
}

$('main').on('scroll', () => {
    headerTop();
});

// tab
$('.tab').on('click', (e) => {
    $('.tab').removeClass('active');
    $(e.target).addClass('active');
    $('.card-box').each((index, card) => {
        $(card).addClass('hidden');
        if ($(e.target).data('type') == $(card).data('type')) {
            $(card).removeClass('hidden');
        }
    });
});